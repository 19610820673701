import useGlobalHook from "use-global-hook";
import React from "react";

const initialState = {
    currentUser: {},
    userLoggedIn: false,
};

export const actions = {
    setCurrentUser: (store, user) => {
        store.setState({currentUser: user});
    },
    setUserLoggedIn: (store, loggedIn) => {
        store.setState({userLoggedIn: loggedIn});
    }
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGlobal = useGlobalHook(React, initialState, actions);
