import React, {useEffect, useState} from "react";



const Doctors = props => {

    useEffect(() => {

    }, []);

    return (
        <section className="patients-page section-padding" id="patients">
            <div className="container-fluid">
                <div className="container">
                    <div className="hero-caption">
                        <h2>Connect with your patients today</h2>
                        <p>Set your available times for consulting convenient to you and your schedule.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature-section">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-6">
                            <div className="benefits-img">
                                <img src="assets/img/patient-create-account.svg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="features-caption">
                                <span className="step">1</span>
                                <h3>. Create Your Doctor Account in Just 5 Minutess</h3>
                                <p>Your information is stored on a secure and approved health data server to guarantee
                                    medical confidentiality.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature-section gray-bg">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-6 align-self-center order-sm-1 order-2">
                            <div className="features-caption">
                                <span className="step">2</span>
                                <h3>Set your availability (days and hours)</h3>
                                <p>Define your working hours and days with flexibility. Whether you prefer to work late evenings or weekends, you can maximize your earnings with consultations.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 order-sm-2 order-1">
                            <div className="benefits-img">
                                <img className="img-fluid" src="assets/img/doctor-schedule.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature-section">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-6">
                            <div className="benefits-img">
                                <img src="assets/img/benefit-prescriptions.svg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="features-caption">
                                <span className="step">3</span>
                                <h3>Consult with Patients and Manage Prescriptions </h3>
                                <p>Provide top-quality care by consulting with your patients online. Easily write and share prescriptions directly with them, simplifying the process for both you and your patients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

)

};

export default Doctors;
