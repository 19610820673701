import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link, NavLink, useLocation} from 'react-router-dom'

const Header = props => {


    return (
        <header>

            <div className="header-area header-transparent ">
                <div className="main-header header-sticky">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container main-menu">
                            <Link className="navbar-brand" to="/">
                                <img src="/assets/img/logo/logo.svg" alt="" width="150"/>
                            </Link>
                            <button className="navbar-toggler hidden-sm-up"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#topnavigation"
                                    aria-controls="topnavigation"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation">
                                &#9776;
                            </button>
                            <div className="collapse navbar-collapse" id="topnavigation">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" data-toggle="collapse"
                                                 data-target=".navbar-collapse.show" exact to="/"
                                                 activeClassName={"active-header"}>Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" data-toggle="collapse"
                                                 data-target=".navbar-collapse.show" exact to="/patients"
                                                 activeClassName={"active-header"}
                                        >For
                                            patients</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" data-toggle="collapse"
                                                 activeClassName={"active-header"}
                                                 data-target=".navbar-collapse.show" exact to="/doctors"
                                        >For
                                            doctors</NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

        </header>
    )
};


export default Header;
