import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import Home from "../Home/Home";
import Patients from "../Patients/Patients";
import Doctors from "../Doctors/Doctors";


const Body = props => {

    useEffect(() => {

    }, []);

    return (
        <main>
            <Switch>

                <Route exact path='/' component={Home}/>
                <Route exact path='/patients' component={Patients}/>
                <Route exact path='/doctors' component={Doctors}/>
            </Switch>
        </main>
    )

};

export default Body;
