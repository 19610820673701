import React from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";

const Footer = props => {

    const [globalState, globalActions] = useGlobal();

    return (
        <footer>

            <div className="footer-main">
                <div className="footer-area">
                    <div className="container">
                        <div className="row align-items-top">
                            <div className="col-md-8">
                                <div className="footer-logo">
                                    <a href="/"><img src="/assets/img/logo/logo-white.svg" alt="" width="150"/></a>
                                </div>
                                <div className="footer-menu">
                                    <ul>
                                        <li>
                                            <Link exact to="/patients">For patients</Link>
                                        </li>
                                        <li>
                                            <Link exact to={"/doctors"}>For doctors</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="station-logo">
                                    <a href="/"><img src="/assets/img/station-f-logo-footer.svg" alt=""
                                                     width="100%"/></a>
                                </div>
                                <p>5 Parvis Alan Turing. 75013 Paris. France</p>

                                <div className="social-buttons">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item">
                                            <a href="https://www.facebook.com/doctarooapp"
                                               className="sbtn btn-large mx-1" title="Facebook">
                                                <i className="fab fa-facebook-square fa-2x"/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="https://www.linkedin.com/company/doctaroo-sas"
                                               className="sbtn btn-large mx-1" title="Linkedin">
                                                <i className="fab fa-linkedin fa-2x"/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="https://twitter.com/doctaroo" className="sbtn btn-large mx-1"
                                               title="Twitter">
                                                <i className="fab fa-twitter-square fa-2x"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xl-12 ">
                                <div className="footer-copy-right">
                                    <p>
                                        Copyright &copy;{new Date().getFullYear()} Doctaroo SAS. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )

};

export default Footer;
