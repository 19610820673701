import React, {useEffect, useState} from "react";



const Patients = props => {

    useEffect(() => {

    }, []);

    return (
        <section className="patients-page section-padding" id="patients">
            <div className="container-fluid">
                <div className="container">
                    <div className="hero-caption">
                        <h2>Talk to a doctor effortlessly, anytime, anywhere.</h2>
                        <p>Enjoy the convenience of fast medical consultations without leaving your home, or get answers while you're out and about.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature-section">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-6">
                            <div className="benefits-img">
                                <img src="assets/img/patient-create-account.svg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="features-caption">
                                <span className="step">1</span>
                                <h3>Create Your Patient Account</h3>
                                <p>Sign up easily and rest assured that your information is securely stored on an approved Health Data server, ensuring your medical confidentiality.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature-section gray-bg">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-6 align-self-center order-sm-1 order-2">
                            <div className="features-caption">
                                <span className="step">2</span>
                                <h3> Consult a Doctor Anytime, Anywhere</h3>
                                <p>Need to see a doctor right away or schedule an appointment? Describe your symptoms through a few simple questions. We'll match you with your doctor, or if they're unavailable, another qualified doctor. All our doctors are registered with the Ordre des médecins, ensuring you receive top-quality care..</p>
                            </div>
                        </div>
                        <div className="col-lg-6 order-sm-2 order-1">
                            <div className="benefits-img">
                                <img src="assets/img/patient-symptoms.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature-section">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-6">
                            <div className="benefits-img">
                                <img src="assets/img/benefit-prescriptions.svg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="features-caption">
                                <span className="step">3</span>
                                <h3>Get Your Prescription and Medicine Delivered</h3>
                                <p>If your doctor prescribes medication, you'll receive a notification. Choose to collect your prescription from a nearby pharmacy or have it delivered straight to your door. Delivery is available in select cities, starting at just €2.50, for your convenience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

};

export default Patients;
