import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Subscribe from "../Subscribe/Subscribe";

const Home = props => {

    const [globalState, globalActions] = useGlobal();


    useEffect(() => {

    }, []);


    return (
        <section>
            <div className="homepage">
                <div className="slider-area ">
                    <div className="slider-active">
                        <div className="single-slider slider-height slider-padding sky-blue d-flex align-items-center">
                            <div className="container">
                                <div className="row d-flex align-items-center">
                                    <div className="col-lg-6 col-md-9">
                                        <div className="hero__caption">
                                            <h1 data-animation="fadeInUp" data-delay=".6s">Your Health, On-Demand.</h1>
                                            <p data-animation="fadeInUp" data-delay=".8s">Talk to a general doctor or specialist within 5 minutes. Fast prescription delivery in under 30 minutes.</p>

                                            <Subscribe/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="hero__img" data-animation="fadeInRight" data-delay="1s">
                                            <img src="/assets/img/hero-doctor.svg" width="100%" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-area section-padding" id="services">
                    <div className="container">
                        <div className="section-title">
                            <h2>How it Works</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="services-caption text-center mb-30">
                                    <div className="service-icon">
                                        <img src="assets/img/iconDownloadApp.svg" width="120" alt=""/>
                                    </div>
                                    <div className="service-cap">
                                        <h4><a href="#">Download the app</a></h4>
                                        <p>Download the app, sign in, and describe your health symptoms.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="services-caption text-center mb-30 covid">
                                    <div className="service-icon">
                                        <img src="assets/img/iconBook.svg" width="120" alt=""/>
                                    </div>
                                    <div className="service-cap">
                                        <h4><a href="#">Book an appointment</a></h4>
                                        <p>Set up a video or call appointment with your personal doctor or one of our doctors.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="services-caption text-center mb-30">
                                    <div className="service-icon">
                                        <img src="assets/img/iconConsult.svg" width="120" alt=""/>
                                    </div>
                                    <div className="service-cap">
                                        <h4><a href="#">Receive health advice </a></h4>
                                        <p>Our doctors give you advice and prescriptions delivered to a pharmacy close
                                            to you.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="best-features-area section-padding" id="features">

                    <div className="container-fluid single-features content-right">
                        <div className="container">
                            <div className="row d-flex justify-content-between">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="benefits-img">
                                        <img className="img-fluid" src="assets/img/benefit-no-waiting.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6 align-self-center">
                                    <div className="features-caption">
                                        <h3>Skip the Wait</h3>
                                        <p>Simply answer a few questions about your symptoms, and we'll set up an appointment with your doctor within minutes. If your doctor is unavailable, we'll find another doctor in our network to assist you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid single-features content-left">
                        <div className="container">
                            <div className="row d-flex justify-content-between">
                                <div className="col-xl-5 col-lg-6 align-self-center order-sm-1 order-2">
                                    <div className="features-caption">
                                        <h3>Receive Your Prescriptions Digitally</h3>
                                        <p>After your doctor prescribes medication during the consultation, we will add it to your profile. You can then choose which pharmacy you'd like us to send the prescription to.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 order-sm-2 order-1">
                                    <div className="benefits-img">
                                        <img src="assets/img/benefit-prescriptions.svg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid single-features content-right">
                        <div className="container">
                            <div className="row d-flex justify-content-between">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="benefits-img">
                                        <img src="assets/img/benefit-delivery.svg" alt=""/>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6 align-self-center">
                                    <div className="features-caption">
                                        <h3>We deliver your prescriptions</h3>
                                        <p>Receive your medication right at your doorstep. We'll securely collect your prescription from a nearby pharmacy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="conditions-area sky-blue section-padding" id="conditions">
                    <div className="container">
                        <div className="section-title">
                            <h2>What can you consult about?</h2>
                        </div>
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-3">
                                <div className="conditions-caption mb-30 align-self-stretch">
                                    <div className="conditions-icon d-flex">
                                        <img src="assets/img/iconInfection.svg" width="64" alt=""/>
                                        <h4 className="align-self-center">Common Infections</h4>
                                    </div>
                                    <div className="conditions-list">
                                        <ul>
                                            <li><span>Fever</span></li>
                                            <li><span>Sore throat</span></li>
                                            <li><span>Colds and flu syndrome</span></li>
                                            <li><span>Allergies</span></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="conditions-caption mb-30 align-self-stretch">
                                    <div className="conditions-icon d-flex">
                                        <img src="assets/img/iconDigestion.svg" width="64" alt=""/>
                                        <h4 className="align-self-center">Digestive Problems</h4>
                                    </div>
                                    <div className="conditions-list">
                                        <ul>
                                            <li><span>Constipation</span></li>
                                            <li><span>Diarrhea or vomiting</span></li>
                                            <li><span>Abdominal pain</span></li>
                                            <li><span>Heartburn and reflux</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="conditions-caption mb-30 align-self-stretch">
                                    <div className="conditions-icon d-flex">
                                        <img src="assets/img/iconAllergy.svg" width="64" alt=""/>
                                        <h4 className="align-self-center">Skin Conditions</h4>
                                    </div>
                                    <div className="conditions-list">
                                        <ul>
                                            <li><span>Acne</span></li>
                                            <li><span>Hair loss</span></li>
                                            <li><span>Rashes and itching</span></li>
                                            <li><span>Insect bites</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="conditions-caption mb-30 align-self-stretch">
                                    <div className="conditions-icon d-flex">
                                        <img src="assets/img/iconBrain.svg" width="64" alt=""/>
                                        <h4 className="align-self-center">Other Conditions</h4>
                                    </div>
                                    <div className="conditions-list">
                                        <ul>
                                            <li><span>Mental health</span></li>
                                            <li><span>Pregnancy tips</span></li>
                                            <li><span>Breastfeeding tips</span></li>
                                            <li><span>Sleep disorders</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Home;
