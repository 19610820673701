import React, {useEffect, useState} from "react";

const Subscribe = props => {

    useEffect(() => {

    }, []);

    const submitForm = e => {
        e.preventDefault()

              alert('Thank you for subscribing! check your email for confirmation.');

    }

    return (
        <div className="pre-subscribe">

            <form id="launch-form" className="form-inline" onSubmit={submitForm}>
                <div data-animation="fadeInLeft" data-delay="1.0s" className="form-group mr-3">
                    <input type="email" className="form-control" name="email" v-model="email"
                           placeholder="Here's my email..."/>
                </div>
                <button type="submit" className="btn btn-primary">Join us</button>


            </form>

        </div>
    )

};

export default Subscribe;
